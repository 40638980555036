<template>
  <div class="card__flex card card-box mb-3">
    <div class="card-header pr-2">
      <div class="card-header--title">
        <small>{{ title }}</small>
        <!-- <h5 class="font-weight-bold my-2">{{ title }}</h5> -->
      </div>
      <div class="card-header--actions">
        <slot name="header-actions"></slot>
      </div>
    </div>

    <div class="card-body p-0">
      <base-table
        ref="table"
        :provider="fetch"
        :fields="fields"
        :action-label="actionLabel"
        :is-busy.sync="isFetching"
        :current-page.sync="currentPage"
        :number-of-pages="mainTableNumberOfPages"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :per-page="mainTablePerPage"
        :show-pagination="false"
        :loading-message="loadingMessage"
        @before:refresh="onBeforeRefresh"
      >
      </base-table>
    </div>
  </div>
</template>

<script>
// import { calculateRetireYear } from "../../helpers/datepicker-helper";
import { orderBy } from "lodash";
import formatDateMixin from "../../mixins/formatDateMixin";
import { Auth, Report } from "../../models";
import BaseTable from "./Base";

export default {
  mixins: [formatDateMixin],

  props: {
    title: String,
    fields: Array,
    params: Object,
    actionLabel: {
      type: String,
      default: "เพิ่ม/แก้ไขข้อมูล",
    },
    loadingMessage: {
      type: String,
      default: "กำลังดาวน์โหลดข้อมูล",
    },
  },

  components: {
    BaseTable,
  },

  data() {
    return {
      isFetching: false,
      currentPage: parseInt(this.$route.query.page) || 1,
      sortBy: "",
      sortDesc: false,
      latestSortBy: null,
      latestSortDesc: null,
      latestSortByTeacher: null,
      latestSortDescTeacher: null,
      perPage: 10,
      numberOfPages: 1,
      overviewTableData: [],
      totalSum: null,
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    mainTableNumberOfPages() {
      return 1;
    },

    mainTablePerPage() {
      return this.overviewTableData.length;
    },
  },

  watch: {
    params: {
      deep: true,
      handler: "onParamsChanged",
    },
  },

  methods: {
    formatPhoneNumber(text) {
      return text && text.length
        ? `${text.substring(0, 3)}-${text.substring(3, 6)}-${text.substring(
            6,
            10
          )}`
        : "-";
    },

    onParamsChanged() {
      this.currentPage = 1;
      this.numberOfPages = 1;

      this.latestSortBy = null;
      this.latestSortDesc = null;

      this.overviewTableData = [];

      this.refresh();
    },

    onBeforeRefresh() {
      this.currentPage = 1;
      this.numberOfPages = 1;

      this.latestSortBy = null;
      this.latestSortDesc = null;

      this.overviewTableData = [];
    },

    async fetch() {
      let data = [],
        numberOfPages = 1;

      const params = {},
        additionalParams = {};

      // if (!ctx.downloadAll) {
      //   this.$set(params, "limit", this.perPage);
      //   this.$set(params, "offset", (this.currentPage - 1) * this.perPage);
      // }

      if (this.params && this.params.mHeadquarterId) {
        this.$set(
          additionalParams,
          "mHeadquarterId",
          this.params.mHeadquarterId
        );
      }

      if (this.params && this.params.mDivisionId) {
        this.$set(additionalParams, "mDivisionId", this.params.mDivisionId);
      }

      if (this.params && this.params.mStationId) {
        this.$set(additionalParams, "id", this.params.mStationId);
      }

      if (this.params && this.params.fiscalYearFrom) {
        this.$set(
          additionalParams,
          "fiscalYearFrom",
          this.params.fiscalYearFrom
        );
      }

      if (this.params && this.params.termFrom) {
        this.$set(additionalParams, "termFrom", this.params.termFrom);
      }

      if (this.params && this.params.retireYearFrom) {
        this.$set(
          additionalParams,
          "retireYearFrom",
          this.params.retireYearFrom
        );
      }

      if (this.params && this.params.fiscalYearTo) {
        this.$set(additionalParams, "fiscalYearTo", this.params.fiscalYearTo);
      }

      if (this.params && this.params.termTo) {
        this.$set(additionalParams, "termTo", this.params.termTo);
      }

      if (this.params && this.params.retireYearTo) {
        this.$set(additionalParams, "retireYearTo", this.params.retireYearTo);
      }

      if (this.params && this.params.startDate) {
        this.$set(additionalParams, "startDate", this.params.startDate);
      }

      if (this.params && this.params.endDate) {
        this.$set(additionalParams, "endDate", this.params.endDate);
      }

      if (!this.overviewTableData.length) {
        this.isFetching = true;

        try {
          const promise = await Report.api().getUserOverviewSummary(
            { ...params, ...additionalParams },
            { save: false }
          );

          let { data: responseData = [] } = promise.response.data;

          let overviewTableData = responseData.map((record) => {
            record = {
              m_headquarters_name: record.headquarter,
              m_division_name: record.division,
              m_station_name: record.station,
              count_total_teachers: record.count_all || 0,
              count_teachers_create_plan: record.count_teacher_create_plan || 0,
              count_teachers_not_create_plan: record.count_teacher_not_create_plan || 0,
            };

            return {
              ...record,
            };
          });

          this.totalSum = {
            m_headquarters_name: "รวม",
            count_total_teachers: 0,
            count_teachers_create_plan: 0,
            count_teachers_not_create_plan: 0,
          };

          overviewTableData.forEach((element) => {
            this.totalSum.count_total_teachers += element.count_total_teachers;
            this.totalSum.count_teachers_create_plan +=
              element.count_teachers_create_plan;
            this.totalSum.count_teachers_not_create_plan +=
              element.count_teachers_not_create_plan;
          });

          this.overviewTableData = [...overviewTableData];

          numberOfPages = Math.ceil(
            this.overviewTableData.length / this.perPage
          );
        } catch (error) {
          this.$toast.error(
            "ไม่สามารถดึงข้อมูลรายงานสรุปทั้งหมดได้ในขณะนี้ กรุณาลองใหม่ภายหลัง"
          );
        } finally {
          this.isFetching = false;
        }

        // if (!ctx.downloadAll) {
        //   this.numberOfPages = numberOfPages;
        // }
        this.numberOfPages = numberOfPages;
      }

      if (this.overviewTableData.length) {
        if (
          this.sortBy &&
          (this.sortBy !== this.latestSortBy ||
            this.sortDesc !== this.latestSortDesc)
        ) {
          this.latestSortBy = this.sortBy;
          this.latestSortDesc = this.sortDesc;

          this.overviewTableData = orderBy(
            this.overviewTableData,
            [this.sortBy],
            [this.sortDesc ? "desc" : "asc"]
          );
        }

        data = [...this.overviewTableData];
      }

      return [...data, this.totalSum];
    },

    refresh() {
      this.$refs.table.refresh();
    },
  },
};
</script>
