<template>
  <b-row>
    <b-col cols="12">
      <b-row align-v="center" align-h="end" class="mb-4">
        <b-col cols="12">
          <filter-fiscal-year-term v-model="formFiscalYearTerm">
          </filter-fiscal-year-term>

          <!-- <filter-master
            class="mt-2"
            v-model="formfilter"
            :m-headquarter-id="
              !authUser.canAccessAllHeadQuarters
                ? authUser.mHeadquarterId
                : null
            "
            :m-division-id="
              !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
            "
            :m-station-id="
              !authUser.canAccessAllStations ? authUser.mStationId : null
            "
            :disabledInput="{
              headQuarter:
                !authUser.canAccessAllHeadQuarters &&
                authUser.mHeadquarterId !== null,
              division:
                !authUser.canAccessAllDivisions &&
                authUser.mDivisionId !== null,
              station:
                !authUser.canAccessAllStations && authUser.mStationId !== null,
            }"
            col-headquarter="4"
            col-division="4"
            col-station="4"
            :hiddenInput="{ school: true, schoolClass: true, room: true }"
          >
          </filter-master> -->

          <filter-master
            class="mt-2"
            v-model="formfilter"
            col-headquarter="4"
            col-division="4"
            col-station="4"
            :hiddenInput="{ school: true, schoolClass: true, room: true }"
          >
          </filter-master>

          <filter-date-between
            v-model="filterDateBetween"
            :col-start-date="4"
            :col-end-date="4"
          >
          </filter-date-between>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12">
      <div class="b-row">
        <b-card>
          <b-row v-if="isFetching" align-h="center">
            <loading
              style="height: 250px"
              message="กำลังดาวน์โหลดสรุปภาพรวมและสถิติ"
            />
          </b-row>

          <b-row v-else>
            <b-col v-if="!noData">
              <b-row align-v="end" align-h="center" class="mb-3">
                <div
                  v-for="serie in series2"
                  :key="`sum-${serie.name}`"
                  class="mx-2"
                >
                  <b>{{ `ยอด${serie.name}ทั้งหมด: ` }}</b
                  >{{ `${numberWithCommas(serie.sum)} คน` }}
                </div>

                <!-- <div class="mx-2">
                  <b>{{ `ยอดค่าตอบแทนการสอนทั้งหมด: ` }}</b
                  >{{ `${numberWithCommas(sumMoney)} บาท` }}
                </div> -->
              </b-row>

              <b-row>
                <b-col cols="12">
                  <apexchart
                    type="bar"
                    height="350"
                    :options="chartOptions"
                    :series="series"
                  />
                </b-col>
              </b-row>

              <b-row align-v="end" align-h="center" class="my-3">
                <!-- <div class="mx-2">
                  <b>{{ `ยอดค่าตอบแทนการสอนทั้งหมด: ` }}</b
                  >{{ `${numberWithCommas(sumMoney)} บาท` }}
                </div> -->

                <div
                  v-for="moneySerieSum in moneySeriesSum"
                  :key="`sum-${moneySerieSum.name}`"
                  class="mx-2"
                >
                  <b>{{ `ยอด${moneySerieSum.name}ทั้งหมด: ` }}</b
                  >{{ `${numberWithCommas(moneySerieSum.sum)} บาท` }}
                </div>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <apexchart
                    type="bar"
                    height="350"
                    :options="moneyChartOptions"
                    :series="moneySeries"
                  />
                </b-col>
              </b-row>
            </b-col>

            <b-col v-else>
              <b-row align-v="center" align-h="center" style="height: 520px">
                ไม่พบข้อมูล
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { map, debounce } from "lodash";
import { Auth, TeachingDare, TransectionType } from "../../models";
import FilterMaster from "../form/FilterMaster";
import FilterDateBetween from "../form/FilterDateBetween";
import FilterFiscalYearTerm from "../form/FilterFiscalYearTerm";
import Loading from "../misc/Loading";

const defaultValue = "ทั้งหมด";

export default {
  components: {
    apexchart: VueApexCharts,
    FilterMaster,
    FilterDateBetween,
    FilterFiscalYearTerm,
    Loading,
  },

  data() {
    return {
      isFetching: false,

      noData: false,

      chartData: [],

      formfilter: {},

      filterDateBetween: {
        startDate: null,
        endDate: null,
      },

      formFiscalYearTerm: {
        fiscalYearFrom: defaultValue,
        termFrom: defaultValue,
        fiscalYearTo: defaultValue,
        termTo: defaultValue,
      },

      // chartOptions2: {
      //   chart: {
      //     offsetX: -10,
      //     offsetY: -10,
      //     shadow: {
      //       enabled: true,
      //       color: "#000",
      //       top: 18,
      //       left: 7,
      //       blur: 10,
      //       opacity: 1,
      //     },
      //     toolbar: {
      //       show: false,
      //     },
      //   },

      //   plotOptions: {
      //     bar: {
      //       horizontal: true,
      //     },
      //   },

      //   colors: ["#d94c53", "#58b5f5"],

      //   dataLabels: {
      //     enabled: true,
      //     formatter: function (val) {
      //       return `${val} คน`;
      //     },
      //     style: {
      //       fontSize: "14px",
      //       fontFamily: "Noto Sans Thai, sans-serif",
      //       colors: ["#333", "#333"],
      //     },
      //   },

      //   stroke: {
      //     curve: "smooth",
      //     colors: ["transparent"],
      //     width: 10,
      //   },

      //   grid: {
      //     borderColor: "#e7e7e7",
      //   },

      //   markers: {
      //     size: 6,
      //   },

      //   xaxis: {
      //     categories: ["นักเรียนตามแผน", "นักเรียนจริง"],
      //     title: {
      //       text: "ทั้งหมด",
      //       rotate: 0,
      //       style: {
      //         fontSize: "14px",
      //         fontFamily: "Noto Sans Thai, sans-serif",
      //       },
      //     },
      //     labels: {
      //       style: {
      //         fontSize: "14px",
      //         fontFamily: "Noto Sans Thai, sans-serif",
      //       },
      //     },
      //   },

      //   yaxis: {
      //     labels: {
      //       show: false,
      //       style: {
      //         fontSize: "14px",
      //         fontFamily: "Noto Sans Thai, sans-serif",
      //       },
      //     },
      //   },

      //   legend: {
      //     show: false,
      //     position: "top",
      //     horizontalAlign: "left",
      //     offsetX: 20,
      //     offsetY: -5,
      //     fontSize: "16px",
      //     fontFamily: "Noto Sans Thai, sans-serif",
      //     markers: {
      //       width: 12,
      //       height: 12,
      //       strokeWidth: 0,
      //       strokeColor: "#fff",
      //       radius: 12,
      //       offsetX: 0,
      //       offsetY: 0,
      //     },
      //   },

      //   tooltip: {
      //     y: {
      //       formatter: function (value) {
      //         return `${value} คน`;
      //       },
      //     },
      //     style: {
      //       fontSize: "14px",
      //       fontFamily: "Noto Sans Thai, sans-serif",
      //     },
      //   },
      // },
    };
  },

  watch: {
    // $route: {
    //   immediate: true,
    //   handler: "onRouteOrFilterChanged",
    // },

    formfilter: {
      deep: true,
      handler: "onRouteOrFilterChanged",
    },

    filterDateBetween: {
      deep: true,
      handler: "onFilterDateBetweenChanged",
    },

    formFiscalYearTerm: {
      deep: true,
      handler: "onFormFiscalYearTermChanged",
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    chartOptions() {
      const { chartData = [] } = this;

      return {
        chart: {
          offsetX: -20,
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          toolbar: {
            show: false,
          },
        },

        colors: ["#9ADBF9", "#85BF87"],

        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} คน`;
          },
          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
            colors: ["#333", "#333"],
          },
        },

        stroke: {
          curve: "smooth",
          colors: ["transparent"],
          width: 10,
        },

        grid: {
          borderColor: "#e7e7e7",
        },

        markers: {
          size: 6,
        },

        xaxis: {
          categories: chartData.map((record) => {
            const { fiscal_year, term } = record;

            let category = fiscal_year;

            if (term) {
              category += `/${term}`;
            }

            return category;
          }),
          title: {
            text: "ปีการศึกษา",
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        yaxis: {
          title: {
            text: "จำนวนนักเรียน",
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
            formatter: function (value) {
              return `${value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            },
          },
        },

        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 20,
          offsetY: -5,
          fontSize: "16px",
          fontFamily: "Noto Sans Thai, sans-serif",
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            radius: 12,
            offsetX: 0,
            offsetY: 0,
          },
        },

        tooltip: {
          y: {
            formatter: function (value) {
              return `${value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} คน`;
            },
          },

          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
          },
        },
      };
    },

    moneyChartOptions() {
      const { chartData = [] } = this;

      return {
        chart: {
          // stacked: true,
          offsetX: -20,
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          toolbar: {
            show: false,
          },
        },

        colors: ["#d94c53", "#9ADBF9"],

        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val && val > 0
              ? `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} บาท`
              : "";
          },
          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
            colors: ["#333", "#333"],
          },
        },

        // title: {
        //   text: "ค่าตอบแทนการสอน",
        //   style: {
        //     fontSize: "14px",
        //     fontFamily: "Noto Sans Thai, sans-serif",
        //     colors: ["#333", "#333"],
        //   },
        // },

        stroke: {
          curve: "smooth",
          colors: ["transparent"],
          width: [0, 10],
        },

        grid: {
          borderColor: "#e7e7e7",
        },

        markers: {
          size: 6,
        },

        xaxis: {
          categories: chartData.map((record) => {
            const { fiscal_year, term } = record;

            let category = fiscal_year;

            if (term) {
              category += `/${term}`;
            }

            return category;
          }),

          title: {
            text: "ปีการศึกษา",
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        yaxis: {
          title: {
            text: "ค่าตอบแทนการสอน",
            // rotate: 0,
            // offsetY: -140,
            // offsetX: 50,
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
            formatter: function (value) {
              return `${value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            },
          },
        },

        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 20,
          offsetY: -5,
          fontSize: "16px",
          fontFamily: "Noto Sans Thai, sans-serif",
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            radius: 12,
            offsetX: 0,
            offsetY: 0,
          },
        },

        tooltip: {
          y: {
            formatter: function (value) {
              return `${value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} บาท`;
            },
          },

          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
          },
        },
      };
    },

    series() {
      const { chartData = [] } = this;

      return [
        {
          name: "นักเรียนตามแผน",
          data: map(chartData, "total_plan_student").map((x) =>
            x ? parseInt(x) : 0
          ),
        },
        {
          name: "นักเรียนจริง",
          data: map(chartData, "total_actual_student").map((x) =>
            x ? parseInt(x) : 0
          ),
        },
      ];
    },

    moneySeries() {
      const { chartData = [] } = this;

      const transactionTypes = TransectionType.all();

      return transactionTypes.map((transactionType) => {
        return {
          name: transactionType.m_transaction_name,
          data: chartData.map((record) => {
            const { transaction_money = {} } = record;

            return parseInt(transaction_money[transactionType.id]) || 0;
          }),
        };
      });

      // return [
      //   {
      //     name: "ค่าตอบแทนการสอน",
      //     data: map(chartData, "total_transaction_money").map((x) =>
      //       x ? parseInt(x) : 0
      //     ),
      //   },
      // ];
    },

    sumMoney() {
      const { chartData = [] } = this;

      return map(chartData, "total_transaction_money").reduce((acc, value) => {
        return acc + value;
      }, 0);
    },

    series2() {
      const { series = [] } = this;

      return series.map((serie) => {
        return {
          ...serie,
          // data: [
          //   serie.data.reduce((res, x) => {
          //     return res + x;
          //   }, 0),
          // ],
          sum: serie.data.reduce((res, x) => {
            return res + x;
          }, 0),
        };
      });
    },

    moneySeriesSum() {
      const { moneySeries = [] } = this;

      return moneySeries.map((moneySerie) => {
        return {
          ...moneySerie,
          sum: moneySerie.data.reduce((res, x) => {
            return res + x;
          }, 0),
        };
      });
    },
  },

  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    async onRouteOrFilterChanged(v) {
      this.$emit("onFilterChanged", v);

      await this.fetch();
    },

    async onFilterDateBetweenChanged() {
      await this.fetch();
    },

    async onFormFiscalYearTermChanged() {
      await this.fetch();
    },

    getFilterTermYear() {
      let params = {};

      if (
        this.formFiscalYearTerm.fiscalYearFrom &&
        this.formFiscalYearTerm.fiscalYearFrom !== defaultValue
      ) {
        this.$set(
          params,
          "fiscalYearFrom",
          this.formFiscalYearTerm.fiscalYearFrom
        );
      }

      if (
        this.formFiscalYearTerm.termFrom &&
        this.formFiscalYearTerm.termFrom !== defaultValue
      ) {
        this.$set(params, "termFrom", this.formFiscalYearTerm.termFrom);
      }

      if (
        this.formFiscalYearTerm.fiscalYearTo &&
        this.formFiscalYearTerm.fiscalYearTo !== defaultValue
      ) {
        this.$set(params, "fiscalYearTo", this.formFiscalYearTerm.fiscalYearTo);
      }

      if (
        this.formFiscalYearTerm.termTo &&
        this.formFiscalYearTerm.termTo !== defaultValue
      ) {
        this.$set(params, "termTo", this.formFiscalYearTerm.termTo);
      }

      return params;
    },

    async fetch() {
      let promise;
      let params = {};

      const { mHeadquarterId, mDivisionId, mStationId, mSchoolId } =
        this.formfilter;

      const { startDate, endDate } = this.filterDateBetween;

      this.noData = false;
      this.isFetching = true;

      if (mHeadquarterId) {
        this.$set(params, "mHeadquarterId", mHeadquarterId);
      }

      if (mDivisionId) {
        this.$set(params, "mDivisionId", mDivisionId);
      }

      if (mStationId) {
        this.$set(params, "mStationId", mStationId);
      }

      if (mSchoolId) {
        this.$set(params, "mSchoolId", mSchoolId);
      }

      if (startDate) {
        this.$set(params, "startDate", startDate);
      }

      if (endDate) {
        this.$set(params, "endDate", endDate);
      }

      try {
        TransectionType.api().findAll();

        promise = await TeachingDare.api().getDashboard({
          ...params,
          ...this.getFilterTermYear(),
        });

        const { data = [] } = promise.response.data;

        if (data.length) {
          this.chartData = [...data];
        } else {
          this.noData = true;
        }
      } catch (error) {
        this.$toast.error(
          "ไม่สามารถดึงสรุปข้อมูลจำนวนนักเรียนได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetching = false;
      }

      return promise;
    },
  },

  created() {
    this.fetch = debounce(this.fetch, 500);
  },
};
</script>
