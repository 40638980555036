<template>
  <div class="rounded">
    <b-row align-v="center" align-h="end" class="mb-4">
      <b-col cols="12">
        <!-- <filter-master
          class="mt-2"
          v-model="formfilter"
          :m-headquarter-id="
            !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
          "
          :m-division-id="
            !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
          "
          :m-station-id="
            !authUser.canAccessAllStations ? authUser.mStationId : null
          "
          :disabledInput="{
            headQuarter:
              !authUser.canAccessAllHeadQuarters &&
              authUser.mHeadquarterId !== null,
            division:
              !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
            station:
              !authUser.canAccessAllStations && authUser.mStationId !== null,
          }"
          col-headquarter="4"
          col-division="4"
          col-station="4"
          :hiddenInput="{ school: true, schoolClass: true, room: true }"
        >
        </filter-master> -->

        <filter-master
          class="mt-2"
          v-model="formfilter"
          col-headquarter="4"
          col-division="4"
          col-station="4"
          :hiddenInput="{ school: true, schoolClass: true, room: true }"
        >
        </filter-master>

        <b-row>
          <b-col class="pr-sm-0">
            <filter-fiscal-year-term
              v-model="formFiscalYearTerm"
              :col-fiscal-year-from="6"
              :col-fiscal-year-to="6"
              label-fiscal-year-from="เริ่มปีที่เริ่มสอน"
              label-fiscal-year-to="ถึงปีที่เริ่มสอน"
              :hidden-input="{ termFrom: true, termTo: true }"
            >
            </filter-fiscal-year-term>
          </b-col>
          <b-col class="pl-sm-0">
            <filter-fiscal-year-term
              v-model="formRetireYear"
              :col-fiscal-year-from="6"
              :col-fiscal-year-to="6"
              label-fiscal-year-from="เริ่มปีที่เกษียญ"
              label-fiscal-year-to="ถึงปีที่เกษียญ"
              :fiscal-year-provider="getRetireYearOptions"
              :hidden-input="{ termFrom: true, termTo: true }"
            >
            </filter-fiscal-year-term>
          </b-col>
        </b-row>

        <filter-date-between
          class="mt-2"
          v-model="filterDateBetween"
          :col-start-date="4"
          :col-end-date="4"
          label-start-date="วันที่เริ่มถูกเพิ่มเข้าสู่ระบบ"
          label-end-date="วันที่สิ้นสุดถูกเพิ่มเข้าสู่ระบบ"
        >
        </filter-date-between>
      </b-col>
    </b-row>

    <div v-if="userDashboards" class="row">
      <div class="col-sm-12 py-3">
        <h4>จำนวนทั้งหมด {{ numberWithCommas(totalUsers) }} คน</h4>
      </div>

      <div class="py-3 col-xl-3 col-md-6 col-sm-12">
        <div class="card card-box card-box-border-bottom">
          <div class="card-body">
            <div class="align-box-row">
              <div class="text-left">
                <div class="d-flex flex-row align-items-baseline">
                  <div
                    class="mt-1 avatar-icon rounded"
                    style="width: 55px; height: 55px"
                  >
                    <img src="@/assets/img/medal-start.png" class="rounded" />
                  </div>
                  <b class="font-size-lg text-black pr-1 ml-3">{{
                    base.medal ? base.medal : ""
                  }}</b>
                </div>
                <div class="line-height-sm mb-2">
                  <b
                    class="font-size-lg text-black pr-1"
                    style="margin-left: 4rem"
                    ><span class="ml-2 text-black-50"
                      >{{
                        base.sum_user_medal
                          ? numberWithCommas(base.sum_user_medal)
                          : "0"
                      }}
                      คน</span
                    ></b
                  >
                </div>
                <b class="font-size-sm text-black-50 pl-2 mt-2">
                  (ชั่วโมงการสอนระหว่าง 0-99 ชั่วโมง)
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-3 col-xl-3 col-md-6 col-sm-12">
        <div class="card card-box card-box-border-bottom">
          <div class="card-body">
            <div class="align-box-row">
              <div class="text-left">
                <div class="d-flex flex-row align-items-baseline">
                  <div
                    class="mt-1 avatar-icon rounded"
                    style="width: 55px; height: 55px"
                  >
                    <img src="@/assets/img/medal-bronze.png" class="rounded" />
                  </div>
                  <b class="font-size-lg text-black pr-1 ml-3">{{
                    bronze.medal ? bronze.medal : ""
                  }}</b>
                </div>
                <div class="line-height-sm mb-2">
                  <b
                    class="font-size-lg text-black pr-1"
                    style="margin-left: 4rem"
                    ><span class="ml-2 text-black-50"
                      >{{
                        bronze.sum_user_medal
                          ? numberWithCommas(bronze.sum_user_medal)
                          : "0"
                      }}
                      คน</span
                    ></b
                  >
                </div>
                <b class="font-size-sm text-black-50 pl-2 mt-2">
                  (ชั่วโมงการสอนครบ 100 ชั่วโมง)</b
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-3 col-xl-3 col-md-6 col-sm-12">
        <div class="card card-box card-box-border-bottom">
          <div class="card-body">
            <div class="align-box-row">
              <div class="text-left">
                <div class="d-flex flex-row align-items-baseline">
                  <div
                    class="mt-1 avatar-icon rounded"
                    style="width: 55px; height: 55px"
                  >
                    <img src="@/assets/img/medal-silver.png" class="rounded" />
                  </div>
                  <b class="font-size-lg text-black pr-1 ml-3">{{
                    silver.medal ? silver.medal : ""
                  }}</b>
                </div>
                <div class="line-height-sm mb-2">
                  <b
                    class="font-size-lg text-black pr-1"
                    style="margin-left: 4rem"
                    ><span class="ml-2 text-black-50"
                      >{{
                        silver.sum_user_medal
                          ? numberWithCommas(silver.sum_user_medal)
                          : "0"
                      }}
                      คน</span
                    ></b
                  >
                </div>
                <b class="font-size-sm text-black-50 pl-2 mt-2">
                  (ชั่วโมงการสอนครบ 150 ชั่วโมง)</b
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-3 col-xl-3 col-md-6 col-sm-12">
        <div class="card card-box card-box-border-bottom">
          <div class="card-body">
            <div class="align-box-row">
              <div class="text-left">
                <div class="d-flex flex-row align-items-baseline">
                  <div
                    class="mt-1 avatar-icon rounded"
                    style="width: 55px; height: 55px"
                  >
                    <img src="@/assets/img/medal-gold.png" class="rounded" />
                  </div>
                  <b class="font-size-lg text-black pr-1 ml-3">{{
                    gold.medal ? gold.medal : ""
                  }}</b>
                </div>
                <div class="line-height-sm mb-2">
                  <b
                    class="font-size-lg text-black pr-1"
                    style="margin-left: 4rem"
                    ><span class="ml-2 text-black-50"
                      >{{
                        gold.sum_user_medal
                          ? numberWithCommas(gold.sum_user_medal)
                          : "0"
                      }}
                      คน</span
                    ></b
                  >
                </div>
                <b class="font-size-sm text-black-50 pl-2 mt-2">
                  (ชั่วโมงการสอนครบ 200 ชั่วโมง)</b
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-card class="mb-3">
      <h5 class="font-weight-bold">เมนูลัด</h5>

      <div class="d-flex">
        <router-link
          class="d-block"
          active-class="active"
          to="/report/overview?preset=current-month"
          exact
        >
          <b-button variant="danger">
            <font-awesome-icon size="sm" icon="book-open" class="mr-2" />
            <span>ดูรายงานภาพรวมแผนการสอนประจำเดือน</span>
          </b-button>
        </router-link>
      </div>
    </b-card>

    <!-- <b-card v-if="isFetching" class="mb-3">
      <loading
        style="height: 250px"
        message="กำลังดาวน์โหลดสรุปภาพรวมครูตำรวจแดร์"
      />
    </b-card>

    <b-card v-if="!isFetching" class="mb-3">
      <base-table
        ref="distributionTable"
        :provider="getDistributionData"
        :fields="distributionFields"
        :current-page="1"
        :number-of-pages="1"
        :sort-by.sync="sortByDistribution"
        :sort-desc.sync="sortDescDistribution"
        :per-page="distributionDasboards.length"
        :show-action="false"
        :show-refresh="false"
        :show-pagination="false"
      ></base-table>
    </b-card> -->

    <report-user-overview-table
      :fields="reportOverviewTableFields"
      :params="reportOverviewTableParams"
      action-label=""
      :show-action="false"
      :see-detail="false"
      :show-delete="false"
      only-main-table
      only-one-page
      loading-message="กำลังดาวน์โหลดสรุปภาพรวมครูตำรวจแดร์"
      class="mb-3"
    />

    <b-row align-v="start" align-h="end" class="mb-4" v-if="!isFetching">
      <b-col class="py-3 col-xl-4 col-md-12">
        <b-card>
          <h5 class="font-weight-bold mb-3 pb-1 border-bottom border-secondary-subtle">ชั่วโมงการสอนอันดับสูงสุด</h5>
          
          <div v-for="(user, index) in userTierDashboards" :key="`user-tier-${index}`">
            <div class="d-flex flex-column">
              <div class="font-weight-bold fs-5 mb-1">
                {{
                  `${
                    user.m_rank.m_rank_name
                      ? user.m_rank.m_rank_name + ` `
                      : ``
                  } ${user.first_name} ${user.last_name}`
                }}
              </div>
              <div class="fs-6">{{ user.total_time }} ชั่วโมง</div>
              <apexchart
                type="bar"
                height="75"
                :options="maxHoursOptions(index)"
                :series="maxHoursSeries(user)"
              />
            </div>
          </div>
          
        </b-card>
      </b-col>

      <b-col class="py-3 col-xl-8 col-md-12">
        <b-card>
          <b-row align-v="center" align-h="end" class="mb-4">
            <b-col cols="6">
              <h5 class="font-weight-bold m-0">จำนวนครูเกษียณ</h5>
            </b-col>

            <b-col cols="6" style="text-align: end;">
              <b-button size="md" class="mr-3" variant="danger" @click="exportReport">
                <b-spinner v-if="isExporting" label="exporting"></b-spinner>
                <span v-else>ดาวน์โหลดรายงานภาพรวมครูเกษียณ</span>
              </b-button>
            </b-col>

            <export-modal v-model="isExporting" />
          </b-row>

          <apexchart
            type="line"
            height="350"
            class="mb-3"
            :options="lineChartRetireYearOptions"
            :series="lineRetireYearSeries"
          />

          <b-row align-v="end" align-h="center" class="mb-3">
            <div v-for="serie in series" :key="`sum-${serie.name}`" class="mx-2">
              <b>{{ `ยอดครูตำรวจแดร์${numberWithCommas(serie.name)}ทั้งหมด: ` }}</b
              >{{ `${numberWithCommas(serie.data[0])} คน` }}
            </div>
          </b-row>

          <apexchart
            type="bar"
            height="250"
            :options="chartOptions"
            :series="series"
          />
        </b-card>
      </b-col>
    </b-row>

    <!-- <b-card v-if="!isFetching">
      <b-row align-v="center" align-h="end" class="mb-4">
        <b-col cols="6">
          <h5 class="font-weight-bold m-0">จำนวนครูเกษียณ</h5>
        </b-col>

        <b-col cols="6" style="text-align: end;">
          <b-button size="md" class="mr-3" variant="danger" @click="exportReport">
            <b-spinner v-if="isExporting" label="exporting"></b-spinner>
            <span v-else>ดาวน์โหลดรายงานภาพรวมครูเกษียณ</span>
          </b-button>
        </b-col>

        <export-modal v-model="isExporting" />
      </b-row>

      <apexchart
        type="line"
        height="350"
        class="mb-3"
        :options="lineChartRetireYearOptions"
        :series="lineRetireYearSeries"
      />

      <b-row align-v="end" align-h="center" class="mb-3">
        <div v-for="serie in series" :key="`sum-${serie.name}`" class="mx-2">
          <b>{{ `ยอดครูตำรวจแดร์${numberWithCommas(serie.name)}ทั้งหมด: ` }}</b
          >{{ `${numberWithCommas(serie.data[0])} คน` }}
        </div>
      </b-row>

      <apexchart
        type="bar"
        height="250"
        :options="chartOptions"
        :series="series"
      />
    </b-card> -->
  </div>
</template>

<script>
import { chain, debounce } from "lodash";
import VueApexCharts from "vue-apexcharts";
import reportMixin from "../../mixins/reportMixin";
import { formatBuddhistBirthDate } from "../../helpers/datepicker-helper";
import { saveAs } from "file-saver";
import XLSX from "xlsx";
import { Auth, User } from "../../models";
import FilterMaster from "../form/FilterMaster";
import FilterDateBetween from "../form/FilterDateBetween";
import FilterFiscalYearTerm from "../form/FilterFiscalYearTerm";
import ExportModal from "../../components/modal/Export";
import ReportUserOverviewTable from "../table/ReportUserOverview";
const defaultValue = "ทั้งหมด";

export default {
  mixins: [reportMixin],

  components: {
    apexchart: VueApexCharts,
    FilterMaster,
    FilterDateBetween,
    FilterFiscalYearTerm,
    ExportModal,
    ReportUserOverviewTable
  },

  data() {
    return {
      isFetching: false,
      totalUsers: 0,
      userDashboards: [],
      userStatusDashboards: [],
      userTierDashboards: [],
      highestTierHours: 0,
      unknownRetireYearCount: 0,
      retireYearDashboards: [],
      distributionDasboards: [],
      base: {
        medal: "ระดับเริ่มต้น",
        sum_user_medal: 0,
      },
      bronze: {
        medal: "ระดับทองแดง",
        sum_user_medal: 0,
      },
      silver: {
        medal: "ระดับเงิน",
        sum_user_medal: 0,
      },
      gold: {
        medal: "ระดับทอง",
        sum_user_medal: 0,
      },
      isExporting: false,
      exportFields: {
        "ยศ ชื่อ-นามสกุล": "user_name",
        เบอร์โทรศัพท์: "phone_number",
        "วัน/เดือน/ปีเกิด": "birth_date",
        ปีเกษียณ: "retire_year",
        "จำนวนชั่วโมงการสอน (ชั่วโมง)": "total_time",
        "จำนวนปีที่สอน (ปี)": "total_year",
        "ปีที่เริ่มสอน พ.ศ.": "start_year",
        ระดับเข็ม: "medal",
        รุ่นครูตำรวจแดร์: "model_no",
        สถานะการเข้าสอนครูตำรวจแดร์: "user_status",
        ภาค: "m_headquarter",
        จังหวัด: "m_division",
        สถานี: "m_station",
      },

      formfilter: {},

      filterDateBetween: {
        startDate: null,
        endDate: null,
      },

      formFiscalYearTerm: {
        fiscalYearFrom: defaultValue,
        fiscalYearTo: defaultValue,
      },

      formRetireYear: {
        fiscalYearFrom: defaultValue,
        fiscalYearTo: defaultValue,
      },

      // sortByDistribution: "",
      // sortDescDistribution: false,
      // latestSortByDistribution: null,
      // latestSortDescDistribution: null,
    };
  },

  watch: {
    formfilter: {
      deep: true,
      handler: "onRouteOrFilterChanged",
    },

    filterDateBetween: {
      deep: true,
      handler: "onFilterDateBetweenChanged",
    },

    formFiscalYearTerm: {
      deep: true,
      handler: "onFormFiscalYearTermChanged",
    },

    formRetireYear: {
      deep: true,
      handler: "onFormRetireYearChanged",
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    minRetireYear() {
      const today = new Date();

      return today.getFullYear() + 543;
    },

    maxRetireYear() {
      const { minRetireYear } = this;

      return minRetireYear + 10;
    },

    reportOverviewTableFields() {
      const mainFields = [
        { key: "m_headquarters_name", label: "ภาค", sortable: true },
      ];

      if (this.formfilter.mHeadquarterId) {
        mainFields.push({
          key: "m_division_name",
          label: "จังหวัด",
          sortable: true,
        });
      }

      if (this.formfilter.mDivisionId) {
        mainFields.push({
          key: "m_station_name",
          label: "สถานี",
          sortable: true,
        });
      }

      const fields = [
        ...mainFields,
        {
          key: "count_total_teachers",
          label: "จำนวนครูตำรวจแดร์ (คน)",
          sortable: true,
        },
        {
          key: "count_teachers_create_plan",
          label: "บันทึกแผนการสอน (คน)",
          sortable: true,
        },
        {
          key: "count_teachers_not_create_plan",
          label: "ไม่บันทึกแผนการสอน (คน)",
          sortable: true,
        }
      ];

      return fields;
    },

    reportOverviewTableParams() {
      const { formfilter, filterDateBetween: dateParams } = this;

      return {
        ...formfilter,
        ...dateParams,
        ...this.getFilterTermYear(),
        ...this.getFilterRetireYear()
      };
    },

    chartOptions() {
      return {
        chart: {
          offsetX: -10,
          offsetY: -10,
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          toolbar: {
            show: false,
          },
        },

        plotOptions: {
          bar: {
            horizontal: true,
          },
        },

        colors: ["#d94c53", "#9ADBF9"],

        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} คน`;
          },
          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
            colors: ["#333", "#333"],
          },
        },

        stroke: {
          curve: "smooth",
          colors: ["transparent"],
          width: 10,
        },

        grid: {
          borderColor: "#e7e7e7",
        },

        markers: {
          size: 6,
        },

        xaxis: {
          categories: ["ไม่เข้าสอน", "เข้าสอน"],
          title: {
            text: "จำนวน",
            rotate: 0,
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
            formatter: function (value) {
              return `${value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            },
          },
        },

        yaxis: {
          labels: {
            show: false,
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          title: {
            text: "สถานะการเข้าสอนครูตำรวจแดร์",
            // rotate: 0,
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        legend: {
          show: true,
          position: "top",
          horizontalAlign: "left",
          offsetX: 20,
          offsetY: 10,
          fontSize: "16px",
          fontFamily: "Noto Sans Thai, sans-serif",
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            radius: 12,
            offsetX: 0,
            offsetY: 0,
          },
        },

        tooltip: {
          x: {
            show: false,
          },
          y: {
            formatter: function (value) {
              return `${value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} คน`;
            },
          },
          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
          },
        },
      };
    },

    // distributionFields() {
    //   let title = "ภาค";

    //   if (this.formfilter.mStationId || this.formfilter.mDivisionId) {
    //     title = "สถานี";
    //   } else {
    //     if (this.formfilter.mHeadquarterId) {
    //       title = "จังหวัด";
    //     }
    //   }

    //   return [
    //     { key: "distribution_name", label: title, sortable: true },
    //     // {
    //     //   key: "count_admin",
    //     //   label: "จำนวน Admin ครูตำรวจแดร์ (คน)",
    //     //   sortable: true,
    //     // },
    //     { key: "count_user", label: "จำนวนครูตำรวจแดร์ (คน)", sortable: true },
    //   ];
    // },

    // distributionChartOptions() {
    //   const { distributionDasboards = [] } = this;

    //   let title = "";

    //   if (this.formfilter.mStationId || this.formfilter.mDivisionId) {
    //     title = "สถานี";
    //   } else {
    //     if (this.formfilter.mHeadquarterId) {
    //       title = "จังหวัด";
    //     } else {
    //       title = "ภาค";
    //     }
    //   }

    //   return {
    //     chart: {
    //       offsetX: -10,
    //       offsetY: -10,
    //       shadow: {
    //         enabled: true,
    //         color: "#000",
    //         top: 18,
    //         left: 7,
    //         blur: 10,
    //         opacity: 1,
    //       },
    //       toolbar: {
    //         show: false,
    //       },
    //     },

    //     plotOptions: {
    //       bar: {
    //         horizontal: true,
    //       },
    //     },

    //     // colors: ["#d94c53", "#58b5f5"],

    //     dataLabels: {
    //       enabled: true,
    //       formatter: function (val) {
    //         return `${val} คน`;
    //       },
    //       style: {
    //         fontSize: "14px",
    //         fontFamily: "Noto Sans Thai, sans-serif",
    //         colors: ["#333", "#333"],
    //       },
    //     },

    //     stroke: {
    //       curve: "smooth",
    //       colors: ["transparent"],
    //       width: 10,
    //     },

    //     grid: {
    //       borderColor: "#e7e7e7",
    //     },

    //     markers: {
    //       size: 6,
    //     },

    //     xaxis: {
    //       categories: distributionDasboards.map(({ distribution_name }) => distribution_name),

    //       title: {
    //         text: "จำนวน",
    //         rotate: 0,
    //         style: {
    //           fontSize: "14px",
    //           fontFamily: "Noto Sans Thai, sans-serif",
    //         },
    //       },
    //       labels: {
    //         style: {
    //           fontSize: "14px",
    //           fontFamily: "Noto Sans Thai, sans-serif",
    //         },
    //       },
    //     },

    //     yaxis: {
    //       labels: {
    //         show: false,
    //         style: {
    //           fontSize: "14px",
    //           fontFamily: "Noto Sans Thai, sans-serif",
    //         },
    //       },
    //       title: {
    //         text: title,
    //         rotate: 0,
    //         style: {
    //           fontSize: "14px",
    //           fontFamily: "Noto Sans Thai, sans-serif",
    //         },
    //       },
    //     },

    //     legend: {
    //       show: true,
    //       position: "top",
    //       horizontalAlign: "left",
    //       offsetX: 20,
    //       offsetY: 10,
    //       fontSize: "14px",
    //       fontFamily: "Noto Sans Thai, sans-serif",
    //       markers: {
    //         width: 12,
    //         height: 12,
    //         strokeWidth: 0,
    //         strokeColor: "#fff",
    //         radius: 12,
    //         offsetX: 0,
    //         offsetY: 0,
    //       },
    //     },

    //     tooltip: {
    //       x: {
    //         show: false,
    //       },
    //       y: {
    //         formatter: function (value) {
    //           return `${value} คน`;
    //         },
    //       },
    //       style: {
    //         fontSize: "14px",
    //         fontFamily: "Noto Sans Thai, sans-serif",
    //       },
    //     },
    //   };
    // },

    lineChartRetireYearOptions() {
      const { retireYearDashboards = [] } = this;

      // let categories = ["ไม่ทราบ"];
      let categories = [];

      const otherYears = chain(retireYearDashboards).map("retire_year").value();

      if (otherYears && otherYears.length) {
        categories = [...categories, ...otherYears];
      }

      return {
        chart: {
          // stacked: true,
          type: "line",
          offsetX: -20,
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          toolbar: {
            show: false,
          },
        },

        colors: ["#9ADBF9", "#85BF87"],

        dataLabels: {
          enabled: false,
          // position: "center",
          // enabledOnSeries: [1],
          // enabledOnSeries: [0],
          formatter: function (val) {
            return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} คน`;
          },
          background: {
            enabled: true,
            foreColor: "#333",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.6,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.45,
            },
          },
          style: {
            fontSize: "12px",
            fontFamily: "Noto Sans Thai, sans-serif",
            colors: ["#fff", "#fff"],
          },
        },

        stroke: {
          curve: "smooth",
          // colors: ["transparent"],
          // width: 10,
          width: [0, 4],
        },

        grid: {
          borderColor: "#e7e7e7",
        },

        // markers: {
        //   size: 6,
        // },

        xaxis: {
          categories,
          title: {
            text: "ปี",
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        yaxis: [
          {
            title: {
              text: "จำนวนครูเกษียณ",
              // rotate: 0,
              // offsetY: -130,
              // offsetX: 30,
              style: {
                fontSize: "12px",
                fontFamily: "Noto Sans Thai, sans-serif",
              },
            },
            labels: {
              style: {
                fontSize: "12px",
                fontFamily: "Noto Sans Thai, sans-serif",
              },
              formatter: function (value) {
                return `${value
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
              },
            },
          },
          {
            opposite: true,
            title: {
              text: "จำนวนครูคงเหลือ",
              // rotate: 0,
              // offsetY: -130,
              // offsetX: 30,
              style: {
                fontSize: "12px",
                fontFamily: "Noto Sans Thai, sans-serif",
              },
            },
            labels: {
              style: {
                fontSize: "12px",
                fontFamily: "Noto Sans Thai, sans-serif",
              },
              formatter: function (value) {
                return `${value
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
              },
            },
          },
        ],

        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 20,
          offsetY: -5,
          fontSize: "16px",
          fontFamily: "Noto Sans Thai, sans-serif",
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            radius: 12,
            offsetX: 0,
            offsetY: 0,
          },
        },

        tooltip: {
          y: {
            formatter: function (value) {
              return `${value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} คน`;
            },
          },

          style: {
            fontSize: "12px",
            fontFamily: "Noto Sans Thai, sans-serif",
          },
        },
      };
    },

    chartRetireYearOptions() {
      const { retireYearDashboards = [] } = this;

      // let categories = ["ไม่ทราบ"];
      let categories = [];

      const otherYears = chain(retireYearDashboards).map("retire_year").value();

      if (otherYears && otherYears.length) {
        categories = [...categories, ...otherYears];
      }

      return {
        chart: {
          // stacked: true,
          type: "bar",
          offsetX: -20,
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          toolbar: {
            show: false,
          },
        },

        colors: ["#d94c53"],

        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return `${val} คน`;
          },
          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
            colors: ["#333", "#333"],
          },
        },

        stroke: {
          curve: "smooth",
          colors: ["transparent"],
          width: [0, 10],
        },

        grid: {
          borderColor: "#e7e7e7",
        },

        markers: {
          size: 6,
        },

        xaxis: {
          categories,
          title: {
            text: "ปี",
            style: {
              fontSize: "12px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "12px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        yaxis: {
          title: {
            text: "จำนวน (คน)",
            rotate: 0,
            offsetY: -130,
            offsetX: 30,
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 20,
          offsetY: -5,
          fontSize: "16px",
          fontFamily: "Noto Sans Thai, sans-serif",
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            radius: 12,
            offsetX: 0,
            offsetY: 0,
          },
        },

        tooltip: {
          y: {
            formatter: function (value) {
              return `${value} คน`;
            },
          },

          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
          },
        },
      };
    },

    chartNotRetireYearOptions() {
      const { retireYearDashboards = [] } = this;

      // let categories = ["ไม่ทราบ"];
      let categories = [];

      const otherYears = chain(retireYearDashboards).map("retire_year").value();

      if (otherYears && otherYears.length) {
        categories = [...categories, ...otherYears];
      }

      return {
        chart: {
          // stacked: true,
          type: "line",
          offsetX: -20,
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          toolbar: {
            show: false,
          },
        },

        colors: ["#58b5f5"],

        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return `${val} คน`;
          },
          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
            colors: ["#333", "#333"],
          },
        },

        stroke: {
          curve: "smooth",
          // colors: ["transparent"],
          // width: [0, 4],
        },

        grid: {
          borderColor: "#e7e7e7",
        },

        markers: {
          size: 6,
        },

        xaxis: {
          categories,
          title: {
            text: "ปี",
            style: {
              fontSize: "12px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "12px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        yaxis: {
          title: {
            text: "จำนวน (คน)",
            rotate: 0,
            offsetY: -130,
            offsetX: 30,
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 20,
          offsetY: -5,
          fontSize: "16px",
          fontFamily: "Noto Sans Thai, sans-serif",
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            radius: 12,
            offsetX: 0,
            offsetY: 0,
          },
        },

        tooltip: {
          y: {
            formatter: function (value) {
              return `${value} คน`;
            },
          },

          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
          },
        },
      };
    },

    lineRetireYearSeries() {
      let { retireYearDashboards = [], totalUsers = 0 } = this;

      // let dataRetire = [this.unknownRetireYearCount];
      // let dataNotRetire = [totalUsers];
      let dataRetire = [];
      let dataNotRetire = [];

      retireYearDashboards.forEach((retireYearDashboard) => {
        const countUserRetire = retireYearDashboard.sum_retire_year
          ? parseInt(retireYearDashboard.sum_retire_year)
          : 0;

        const countUserNotRetire = totalUsers - countUserRetire;

        totalUsers -= countUserRetire;

        dataRetire.push(countUserRetire);
        dataNotRetire.push(countUserNotRetire);
      });

      return [
        {
          name: "จำนวนครูเกษียณ",
          type: "column",
          data: dataRetire,
        },
        {
          name: "จำนวนครูคงเหลือ",
          type: "line",
          data: dataNotRetire,
        },
      ];
    },

    retireYearSeries() {
      let { retireYearDashboards = [] } = this;

      let dataRetire = [this.unknownRetireYearCount];
      // let dataNotRetire = [0];

      retireYearDashboards.forEach((retireYearDashboard) => {
        const countUserRetire = retireYearDashboard.sum_retire_year
          ? parseInt(retireYearDashboard.sum_retire_year)
          : 0;

        // const countUserNotRetire = totalUsers - countUserRetire;

        // totalUsers -= countUserRetire;

        dataRetire.push(countUserRetire);
        // dataNotRetire.push(countUserNotRetire);
      });

      return [
        {
          name: "จำนวนครูเกษียณ",
          data: dataRetire,
        },
        // {
        //   name: "จำนวนครูคงเหลือ",
        //   data: dataNotRetire,
        // },
      ];
    },

    notRetireYearSeries() {
      let { retireYearDashboards = [], totalUsers = 0 } = this;

      // let dataRetire = [this.unknownRetireYearCount];
      let dataNotRetire = [0];

      retireYearDashboards.forEach((retireYearDashboard) => {
        const countUserRetire = retireYearDashboard.sum_retire_year
          ? parseInt(retireYearDashboard.sum_retire_year)
          : 0;

        const countUserNotRetire = totalUsers - countUserRetire;

        totalUsers -= countUserRetire;

        // dataRetire.push(countUserRetire);
        dataNotRetire.push(countUserNotRetire);
      });

      return [
        // {
        //   name: "จำนวนครูเกษียณ",
        //   data: dataRetire,
        // },
        {
          name: "จำนวนครูคงเหลือ",
          data: dataNotRetire,
        },
      ];
    },

    // chartOptions() {
    //   const { userStatusDashboards = [] } = this;

    //   return {
    //     chart: {
    //       offsetX: -20,
    //       shadow: {
    //         enabled: true,
    //         color: "#000",
    //         top: 18,
    //         left: 7,
    //         blur: 10,
    //         opacity: 1,
    //       },
    //       toolbar: {
    //         show: false,
    //       },
    //     },

    //     colors: ["#d94c53", "#58b5f5"],

    //     dataLabels: {
    //       enabled: false,
    //     },

    //     stroke: {
    //       curve: "smooth",
    //       colors: ["transparent"],
    //       width: 10,
    //     },

    //     grid: {
    //       borderColor: "#e7e7e7",
    //     },

    //     markers: {
    //       size: 6,
    //     },

    //     xaxis: {
    //       categories: map(userStatusDashboards, "user_status"),
    //       title: {
    //         text: "สถานะ",
    //         style: {
    //           fontSize: "14px",
    //           fontFamily: "Noto Sans Thai, sans-serif",
    //         },
    //       },
    //       labels: {
    //         style: {
    //           fontSize: "14px",
    //           fontFamily: "Noto Sans Thai, sans-serif",
    //         },
    //       },
    //     },

    //     yaxis: {
    //       title: {
    //         text: "จำนวน",
    //         rotate: 0,
    //         offsetY: -130,
    //         offsetX: 30,
    //         style: {
    //           fontSize: "14px",
    //           fontFamily: "Noto Sans Thai, sans-serif",
    //         },
    //       },
    //       labels: {
    //         style: {
    //           fontSize: "14px",
    //           fontFamily: "Noto Sans Thai, sans-serif",
    //         },
    //       },
    //     },

    //     legend: {
    //       position: "top",
    //       horizontalAlign: "left",
    //       offsetX: 20,
    //       offsetY: -5,
    //       fontSize: "16px",
    //       fontFamily: "Noto Sans Thai, sans-serif",
    //       markers: {
    //         width: 12,
    //         height: 12,
    //         strokeWidth: 0,
    //         strokeColor: "#fff",
    //         radius: 12,
    //         offsetX: 0,
    //         offsetY: 0,
    //       },
    //     },

    //     tooltip: {
    //       style: {
    //         fontSize: "14px",
    //         fontFamily: "Noto Sans Thai, sans-serif",
    //       },
    //     },
    //   };
    // },

    series() {
      const { userStatusDashboards = [] } = this;

      return userStatusDashboards.map((userStatusDashboard) => {
        return {
          name: userStatusDashboard.user_status,
          data: [
            userStatusDashboard.sum_user_status
              ? parseInt(userStatusDashboard.sum_user_status)
              : 0,
          ],
        };
      });
    },

    // distributionSeries() {
    //   const { distributionDasboards = [] } = this;

    //   return distributionDasboards.map((distributionDasboard) => {
    //     return {
    //       name: distributionDasboard.distribution_name,
    //       data: [
    //         distributionDasboard.sum_distribution
    //           ? parseInt(distributionDasboard.sum_distribution)
    //           : 0,
    //       ],
    //     };
    //   });
    // },

    // totalUsers() {
    //   const { userStatusDashboards = [] } = this;

    //   return userStatusDashboards.reduce((acc, userStatusDashboard) => {
    //     return acc + parseInt(userStatusDashboard.sum_user_status);
    //   }, 0);
    // },
  },

  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    // async fetchExport() {
    //   let data = [];

    //   this.isExporting = true;

    //   try {
    //     data = await this.$refs.table.fetch({ downloadAll: true });
    //   } catch (error) {
    //     this.$toast.error("ไม่สามารถดึงข้อมูลรายงานได้ กรุณาลองใหม่ภายหลัง");
    //   } finally {
    //     this.isExporting = false;
    //   }

    //   return data;
    // },

    maxHoursOptions(index) {
      return {
        chart: {
          offsetX: -10,
          offsetY: -10,
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          toolbar: {
            show: false,
          },
        },

        plotOptions: {
          bar: {
            horizontal: true,
          },
        },

        colors: index % 2 == 0 ? ["#77D9CD"] : ["#02A0FC"],

        dataLabels: {
          enabled: false,
        },

        stroke: {
          curve: "smooth",
          colors: ["transparent"],
          width: 10,
        },

        grid: {
          borderColor: "#e7e7e7",
        },

        markers: {
          size: 6,
        },

        xaxis: {
          categories: ["ชั่วโมงการสอน"],
          max: this.highestTierHours,
          // title: {
          //   text: "จำนวน",
          //   rotate: 0,
          //   style: {
          //     fontSize: "14px",
          //     fontFamily: "Noto Sans Thai, sans-serif",
          //   },
          // },
          labels: {
            show: false,
          },
        },

        yaxis: {
          labels: {
            show: false,
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          // title: {
          //   text: "สถานะการเข้าสอนครูตำรวจแดร์",
          //   // rotate: 0,
          //   style: {
          //     fontSize: "14px",
          //     fontFamily: "Noto Sans Thai, sans-serif",
          //   },
          // },
        },

        legend: {
          show: false,
          position: "top",
          horizontalAlign: "left",
          offsetX: 20,
          offsetY: 10,
          fontSize: "16px",
          fontFamily: "Noto Sans Thai, sans-serif",
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            radius: 12,
            offsetX: 0,
            offsetY: 0,
          },
        },

        tooltip: {
          x: {
            show: false,
          },
          y: {
            formatter: function (value) {
              return `${value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ชั่วโมง`;
            },
          },
          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
          },
        },
      };
    },

    maxHoursSeries(item) {
      return ([
        {
          name: `ชั่วโมงการสอน`,
          data: [
            item.total_time
              ? parseInt(item.total_time)
              : 0,
          ],
        }
      ]);
    },

    async exportReport() {
      let data = this.retireYearDashboards;

      if (data && data.length) {
        this.isExporting = true;

        const wb = this.exportMultiplePage(data);

        /* bookType can be any supported output type */
        const wopts = { bookType: "xlsx", bookSST: false, type: "array" };

        const wbout = XLSX.write(wb, wopts);

        this.isExporting = false;

        /* the saveAs call downloads a file on the local machine */
        saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          "รายงานภาพรวมครูเกษียณ.xlsx"
        );
      }
    },

    exportMultiplePage(data) {
      let exportFields = { ...this.exportFields };

      let header = Object.keys(exportFields);

      const wb = XLSX.utils.book_new();

      let dataOverview = [];

      data.forEach((element, index) => {
        const res = {
          ลำดับ: index + 1,
        };

        res["ปีเกษียณ"] = element.retire_year;
        res["จำนวนครูเกษียณ"] = element.sum_retire_year;

        dataOverview.push(res);
      });

      let sheetHeader_overview = ["ลำดับ", "ปีเกษียณ", "จำนวนครูเกษียณ"];
      let sheetHeader = ["ลำดับ", ...header];

      let ws = XLSX.utils.json_to_sheet(dataOverview, {
        header: sheetHeader_overview,
      });

      let { startDate, endDate } = this.filterDateBetween;

      if (startDate || endDate) {
        if (startDate) {
          startDate = formatBuddhistBirthDate(new Date(startDate));
        }

        if (endDate) {
          endDate = formatBuddhistBirthDate(new Date(endDate));
        }

        ws = this.$_reportMixin_addDate(ws, { startDate, endDate });
      }

      ws = this.$_reportMixin_setWidthColumn(
        ws,
        sheetHeader_overview,
        dataOverview
      );

      XLSX.utils.book_append_sheet(wb, ws, "ภาพรวมครูเกษียณ");

      data.forEach((element) => {
        const groupName = element.retire_year;
        let { data } = element;

        data = data.map((record, index) => {
          const res = {
            ลำดับ: index + 1,
          };

          if (record.m_rank) {
            record.user_name = `${record.m_rank.m_rank_name}${record.first_name} ${record.last_name}`;
          } else {
            record.user_name = `${record.first_name} ${record.last_name}`;
          }

          record.m_headquarter =
            record.m_station &&
            record.m_station.m_division &&
            record.m_station.m_division.m_headquarter
              ? record.m_station.m_division.m_headquarter.m_headquarters_name
              : "-";
          record.m_division =
            record.m_station && record.m_station.m_division
              ? record.m_station.m_division.m_division_name
              : "-";
          record.m_station = record.m_station
            ? record.m_station.m_station_name
            : "-";

          header.forEach((col) => {
            res[col] = record[exportFields[col]];
          });

          return res;
        });

        let ws = XLSX.utils.json_to_sheet(data, {
          header: sheetHeader,
        });

        let { startDate, endDate } = this.filterDateBetween;

        if (startDate || endDate) {
          if (startDate) {
            startDate = formatBuddhistBirthDate(new Date(startDate));
          }

          if (endDate) {
            endDate = formatBuddhistBirthDate(new Date(endDate));
          }

          ws = this.$_reportMixin_addDate(ws, { startDate, endDate });
        }

        ws = this.$_reportMixin_mergeCellBy(ws, this.colKey, {
          orderCol: "A",
        });

        ws = this.$_reportMixin_setWidthColumn(ws, sheetHeader, data);

        XLSX.utils.book_append_sheet(wb, ws, groupName);
      });

      return wb;
    },

    async onRouteOrFilterChanged() {
      await this.fetch();
    },

    async onFilterDateBetweenChanged() {
      await this.fetch();
    },

    async onFormFiscalYearTermChanged() {
      await this.fetch();
    },

    async onFormRetireYearChanged() {
      await this.fetch();
    },

    getRetireYearOptions() {
      const retireYearOptions = [];

      for (let year = this.minRetireYear; year <= this.maxRetireYear; year++) {
        retireYearOptions.push(year);
      }

      return retireYearOptions;
    },

    getFilterTermYear() {
      let params = {};

      if (
        this.formFiscalYearTerm.fiscalYearFrom &&
        this.formFiscalYearTerm.fiscalYearFrom !== defaultValue
      ) {
        this.$set(
          params,
          "fiscalYearFrom",
          this.formFiscalYearTerm.fiscalYearFrom
        );
      }

      if (
        this.formFiscalYearTerm.fiscalYearTo &&
        this.formFiscalYearTerm.fiscalYearTo !== defaultValue
      ) {
        this.$set(params, "fiscalYearTo", this.formFiscalYearTerm.fiscalYearTo);
      }

      return params;
    },

    getFilterRetireYear() {
      let params = {};

      if (
        this.formRetireYear.fiscalYearFrom &&
        this.formRetireYear.fiscalYearFrom !== defaultValue
      ) {
        this.$set(params, "retireYearFrom", this.formRetireYear.fiscalYearFrom);
      }

      if (
        this.formRetireYear.fiscalYearTo &&
        this.formRetireYear.fiscalYearTo !== defaultValue
      ) {
        this.$set(params, "retireYearTo", this.formRetireYear.fiscalYearTo);
      }

      return params;
    },

    // getDistributionData() {
    //   if (
    //     this.sortByDistribution &&
    //     (this.sortByDistribution !== this.latestSortByDistribution ||
    //       this.sortDescDistribution !== this.latestSortDescDistribution)
    //   ) {
    //     this.latestSortByDistribution = this.sortByDistribution;
    //     this.latestSortDescDistribution = this.sortDescDistribution;

    //     // console.log(
    //     //   "Sort teacher change:",
    //     //   this.sortByDistribution,
    //     //   this.sortDescDistribution
    //     // );

    //     this.distributionDasboards = orderBy(
    //       this.distributionDasboards,
    //       [this.sortByDistribution],
    //       [this.sortDescDistribution ? "desc" : "asc"]
    //     );
    //   }

    //   return this.distributionDasboards;
    // },

    async fetch() {
      let params = {};

      const { mHeadquarterId, mDivisionId, mStationId, mSchoolId } =
        this.formfilter;

      const { startDate, endDate } = this.filterDateBetween;

      if (mHeadquarterId) {
        this.$set(params, "mHeadquarterId", mHeadquarterId);
      }

      if (mDivisionId) {
        this.$set(params, "mDivisionId", mDivisionId);
      }

      if (mStationId) {
        this.$set(params, "mStationId", mStationId);
      }

      if (mSchoolId) {
        this.$set(params, "mSchoolId", mSchoolId);
      }

      if (startDate) {
        this.$set(params, "startDate", startDate);
      }

      if (endDate) {
        this.$set(params, "endDate", endDate);
      }

      this.isFetching = true;

      try {
        let { response } = await User.api().getDashboard({
          ...params,
          ...this.getFilterTermYear(),
          ...this.getFilterRetireYear(),
        });

        if (response.data) {
          this.totalUsers = response.data.total_users;

          this.userDashboards = [...response.data.data];

          this.userStatusDashboards = [...response.data.user_status];
          
          this.userTierDashboards = [...response.data.user_tier];

          let numDigit = response.data.user_tier[0].total_time.toString().length;

          this.highestTierHours = Math.ceil(response.data.user_tier[0].total_time / (Math.pow(10, numDigit - 1))) * (Math.pow(10, numDigit - 1));

          // this.distributionDasboards = [
          //   ...chain(
          //     response.data.user_distribution.map((user_distribution) => {
          //       return {
          //         ...user_distribution,
          //         count_user: user_distribution.count_user
          //           .toString()
          //           .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          //       };
          //     })
          //   )
          //     .orderBy(["distribution_name"], ["asc"])
          //     .value(),
          // ];

          this.unknownRetireYearCount = 0;

          this.retireYearDashboards = [];

          if (response.data.user_retire_year) {
            const { user_retire_year = [] } = response.data;

            user_retire_year.forEach((retire_record) => {
              if (retire_record.retire_year === "ไม่ทราบ") {
                this.unknownRetireYearCount = retire_record.sum_retire_year;
              } else {
                this.retireYearDashboards.push({ ...retire_record });
              }
            });

            if (this.retireYearDashboards.length) {
              this.retireYearDashboards = [
                ...chain(
                  this.retireYearDashboards.filter(({ retire_year } = {}) => {
                    retire_year = parseInt(retire_year);

                    return (
                      retire_year >= this.minRetireYear &&
                      retire_year <= this.maxRetireYear
                    );
                  })
                )
                  .orderBy(["retire_year"], ["asc"])
                  .value(),
              ];
            }
          }

          this.$set(this.base, "sum_user_medal", 0);

          this.$set(this.bronze, "sum_user_medal", 0);

          this.$set(this.silver, "sum_user_medal", 0);

          this.$set(this.gold, "sum_user_medal", 0);

          for (let index = 0; index < this.userDashboards.length; index++) {
            const userDashboard = this.userDashboards[index];
            if (userDashboard.medal == null) {
              this.$set(
                this.base,
                "sum_user_medal",
                this.base.sum_user_medal +
                  parseInt(userDashboard.sum_user_medal)
              );
            }
            if (userDashboard.medal == "ระดับต้น") {
              this.$set(
                this.base,
                "sum_user_medal",
                this.base.sum_user_medal +
                  parseInt(userDashboard.sum_user_medal)
              );
            }
            if (userDashboard.medal == "ระดับทองแดง") {
              this.$set(
                this.bronze,
                "sum_user_medal",
                this.bronze.sum_user_medal +
                  parseInt(userDashboard.sum_user_medal)
              );
            }
            if (userDashboard.medal == "ระดับเงิน") {
              this.$set(
                this.silver,
                "sum_user_medal",
                this.silver.sum_user_medal +
                  parseInt(userDashboard.sum_user_medal)
              );
            }
            if (userDashboard.medal == "ระดับทอง") {
              this.$set(
                this.gold,
                "sum_user_medal",
                this.gold.sum_user_medal +
                  parseInt(userDashboard.sum_user_medal)
              );
            }
          }
        }
      } catch (error) {
        this.$toast.error(
          "ไม่สามารถโหลดข้อมูลภาพรวมครูตำรวจแดร์ได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetching = false;
      }
    },
  },

  async created() {
    this.fetch = debounce(this.fetch, 500);
  },
};
</script>
