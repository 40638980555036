<template>
  <div>
    <page-content>
      <page-title heading="สรุปภาพรวมครูตำรวจแดร์">
        <!-- <template #right>
          <b-button size="sm">
            <font-awesome-icon
              :spin="isRefreshing"
              size="sm"
              icon="sync"
              @click="refresh_user_rank"
            />
          </b-button>
        </template> -->
      </page-title>

      <chart-user-rank ref="user_rank" class="" />
    </page-content>

    <br />

    <page-content>
      <page-title heading="สรุปภาพรวมและสถิติ">
        <template #right>
          <b-button size="sm" @click="refreshTeachingDare">
            <font-awesome-icon
              :spin="isRefreshingTeachingDare"
              size="sm"
              icon="sync"
            />
          </b-button>
        </template>
      </page-title>

      <chart-teaching-dare ref="chartTeachingDare" class="mb-5" />
    </page-content>

    <br />

    <page-content>
      <page-title heading="สรุปภาพรวมการสมัครสมาชิกชมรม D.A.R.E">
        <template #right>
          <b-button size="sm" @click="refreshChartRegistration">
            <font-awesome-icon
              :spin="isRefreshingChartRegistration"
              size="sm"
              icon="sync"
            />
          </b-button>
        </template>
      </page-title>

      <chart-registration ref="chartRegistration" class="mb-5" />
    </page-content>

    <br />

    <!-- <page-content>
      <page-title heading="สรุปภาพรวมผลการประเมิน">
        <template #right>
          <b-button size="sm" @click="refresh_survey">
            <font-awesome-icon
              :spin="isRefreshingSurvey"
              size="sm"
              icon="sync"
            />
          </b-button>
        </template>
      </page-title>

      <chart-survey ref="chartSurvey" class="mb-5" />
    </page-content> -->
  </div>
</template>

<script>
import PageContent from "../components/layout/PageContent";
import PageTitle from "../components/layout/PageTitle";
import ChartTeachingDare from "../components/chart/TeachingDare";
// import ChartSurvey from "../components/chart/Survey";
import ChartUserRank from "../components/chart/UserRank";
import ChartRegistration from "../components/chart/Registration";

export default {
  components: {
    PageContent,
    PageTitle,
    ChartTeachingDare,
    // ChartSurvey,
    ChartUserRank,
    ChartRegistration,
  },

  data() {
    return {
      isRefreshingTeachingDare: false,
      isRefreshingSurvey: false,
      isRefreshingChartRegistration: false,
    };
  },

  methods: {
    refreshTeachingDare() {
      this.$nextTick(async () => {
        this.isRefreshingTeachingDare = true;

        await this.$refs.chartTeachingDare.fetch();

        setTimeout(() => {
          this.isRefreshingTeachingDare = false;
        }, 2000);
      });
    },

    refresh_survey() {
      this.$nextTick(async () => {
        this.isRefreshingSurvey = true;

        await this.$refs.chartSurvey.fetch();

        setTimeout(() => {
          this.isRefreshingSurvey = false;
        }, 2000);
      });
    },

    refreshChartRegistration() {
      this.$nextTick(async () => {
        this.isRefreshingChartRegistration = true;

        await this.$refs.chartRegistration.fetch();

        setTimeout(() => {
          this.isRefreshingChartRegistration = false;
        }, 2000);
      });
    },

    // refresh_user_rank() {
    //   this.$nextTick(async () => {
    //     this.isRefreshing = true;

    //     await this.$refs.user_rank.fetch();

    //     setTimeout(() => {
    //       this.isRefreshing = false;
    //     }, 2000);
    //   });
    // },
  },
};
</script>
